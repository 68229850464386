<template>
    <div id="policybox">
        <div class="hadheight"></div>
        <Header 
            @hedProduct="getHedProductData"
            @b2listwt="getb2listwt"
         />
        <div class="webmap-sec1 base100">
            <div class="basecont">
                <div class="f_tit basefont40">
                    网站地图
                </div>
                <div class="item">
                    <router-link to="/product" class="tit basefont24">P2B</router-link>
                    <div class="jut">
                        <template v-for="item in hedpro">
                            <router-link :to="{name: 'Productlist',params:{pan: 1,id: item.id,title:item.name}}" class="li" :key="item.id" :title="item.name">
                                {{item.name}}
                            </router-link>
                        </template>
                        
                    </div>
                </div>
                <div class="item">
                    <router-link to="/brand" class="tit basefont24">B2P</router-link>
                    <div class="jut" v-if="b2pconter">
                        <template v-for="item in b2pconter">
                            <router-link :to="{name: 'Brandlist',query:{title:item}}" class="li" :key="item" :title="item">
                                {{item}}
                            </router-link>
                        </template>
                    </div>
                </div>
                <div class="item">
                    <router-link to="/case" class="tit basefont24">品牌案例</router-link>
                </div>
                <div class="item">
                    <router-link to="/faq" class="tit basefont24">常见问题</router-link>
                </div>
                <div class="item">
                    <router-link to="/cooperation" class="tit basefont24">商务合作</router-link>
                </div>
                <div class="item">
                    <router-link to="/about" class="tit basefont24">关于我们</router-link>
                </div>
                <div class="item">
                    <router-link to="/about" class="tit basefont24">联系我们</router-link>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default{
    name: "Policy",
    components:{
        Header,
        Footer
    },
    data(){
        return {
            xie: "",
            hedpro:{},
            b2pconter: '',
        }
    },
    created:function(){
       
    },
    methods:{
        getHedProductData(data){
            this.hedpro=data;
        },
        getb2listwt(data){
            
            this.b2pconter = data.attrValueList;
        }
    }
}
</script>
